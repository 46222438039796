<template>
    <!-- FOOTER -->
    <footer class="footer">
        <div class="footer-brand">
            <img class="logo-text" src="@/assets/images/logo/logo_text.svg" alt="logo">
            <div class="info">
                <h4>상호 : 쇼잉글리쉬 | 사업자등록번호 : 120-91-05928 | 대표자명 : 김 민</h4>
                <h4>고객 문의 : <span style="text-decoration: none; color: inherit;">support@readigo.co.kr</span>
                    | 제휴 문의 : 02-555-4541 | 전화번호 : 02-555-4541</h4>
                <h4>통신판매업신고번호 : </h4>
                <div class="policy">
                    <a href="">서비스이용약관</a> | <a href="">개인정보처리방침</a> | <a href="">청소년보호정책</a> | <a
                    href="">컨텐츠저작권규약</a>
                    | <a href="">환불정책</a>
                </div>
                <div class="copyright">
                    ⓒ 2024 READIGO KOREA. ALL RIGHTS RESERVED
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'BrandFooter'
};
</script>

<style>
</style>
