import axios from 'axios';
import router from '@/router';
import store from '@/store';
import {logoutApi} from "@/api/auth/accountApi";

// axios 인스턴스 생성
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 15000
});

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed() {
    refreshSubscribers.forEach(callback => callback());
    refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
    refreshSubscribers.push(callback);
}

axiosInstance.interceptors.request.use(
    (config) => {
        if (!store.getters['auth/isAuthenticated'] && !config.url.startsWith('/auth')) {
            router.push('/auth/login');
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const {status, data} = error.response || {};
        const originalRequest = error.config;

        // Access Token 만료 시 (401 + EXPIRED_ACCESS_TOKEN)
        if (status === 401 && data?.code === "EXPIRED_ACCESS_TOKEN") {
            if (isRefreshing) {
                return new Promise((resolve) => {
                    addRefreshSubscriber(() => {
                        resolve(axiosInstance.request(originalRequest)); // Access Token 이 자동 갱신되므로 그대로 재시도
                    });
                });
            }

            isRefreshing = true;

            try {
                await axiosInstance.post('/auth/refresh', null, {withCredentials: true});
                onRefreshed();
                return axiosInstance.request(originalRequest);
            } catch (refreshError) {
                if (refreshError.response?.status === 401 && refreshError.response?.data?.code === "EXPIRED_REFRESH_TOKEN") {
                    await logoutApi();
                    await store.dispatch('auth/logoutAction');
                    router.push('/auth/login');
                } else {
                    alert('일시적인 서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                }
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        } else if (status === 401 && data?.code === "EXPIRED_REFRESH_TOKEN") {
            await logoutApi();
            await store.dispatch('auth/logoutAction');
            router.push('/auth/login');
        }

        switch (status) {
            case 401:
                await store.dispatch('auth/logoutAction');
                router.push('/auth/login');
                break;
            case 403:
                if (data?.code) {
                    alert('권한이 존재하지 않습니다.');
                    await router.replace('/');
                } else {
                    alert('잘못된 접근입니다.');
                    window.location.href = 'https://readigo.co.kr';
                }
                break;
            case 502:
                await store.dispatch('auth/logoutAction');
                router.push('/auth/login');
                break;
            default:
                break;
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
