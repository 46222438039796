<template>
    <ServiceLnb v-if="$route.meta.requiresLnb !== false"/>
    <div class="content">
        <ServiceHeader v-if="$route.meta.requiresHeader !== false"/>
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
import ServiceHeader from '@/components/layout/ServiceHeader.vue';
import ServiceLnb from '@/components/layout/ServiceLnb.vue';

export default {
    name: 'ServiceLayout',
    components: {
        ServiceHeader,
        ServiceLnb,
    },
    mounted() {
        //this.$adjustContentWrapperMargin();
    }
};
</script>

<style scoped lang="scss">

</style>
