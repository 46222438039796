<template>
    <header class="header" style="border-bottom: 1px solid rgba(32, 32, 32, 0.1)">
        <header class="header-brand">
            <!-- MOBILE MENU -->
            <div class="mobile-menu icon-medium" @click="toggleMenu">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6ZM2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12ZM3 17.25C2.58579 17.25 2.25 17.5858 2.25 18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18C21.75 17.5858 21.4142 17.25 21 17.25H3Z"
                          fill="currentColor"/>
                </svg>
            </div>

            <a class="logo" @click="goMenu('/brand')">
                <img class="logo-text" src="@/assets/images/logo/logo_text.svg" alt="logo">
                <img class="logo-icon" src="@/assets/images/logo/logo_icon.svg" alt="logo">
            </a>
            <div class="brand-menu">
                <div class="close icon-medium" @click="closeMenu">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 5L5 19M5 5L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <a :class="{ active : selectedMenu === 'home'}" @click="goMenu('/brand')">서비스소개</a>
                <a :class="{ active : selectedMenu === 'study'}" @click="goMenu('/brand/study')">학습소개</a>
                <a :class="{ active : selectedMenu === 'subscription'}" @click="goMenu('/brand/subscription')" v-if="isIndividualCompany()">요금제</a>
                <a :class="{ active : selectedMenu === 'partner'}" @click="goMenu('/brand/partner')" v-if="isIndividualCompany()">B2B 프로그램</a>
            </div>
            <button class="user-auth button-square-large" @click="$goPage('/auth/login')">로그인/회원가입</button>
        </header>
    </header>
</template>
<script>
export default {
    data() {
        return {
            selectedMenu: ''
        };
    },
    mounted() {
        console.log('BrandHeader mounted');
        this.selectMenu(this.$route.path);
    },
    methods: {
        toggleMenu() {
            const menu = document.querySelector('.brand-menu'); // Select the nav.menu element
            const isMenuVisible = menu.style.display === 'flex';
            if (!isMenuVisible) {
                menu.style.display = 'flex'; // Show the menu
            } else {
                menu.style.display = 'none'; // Hide the menu
            }
        },
        closeMenu() {
            const menu = document.querySelector('.brand-menu'); // Select the nav.menu element
            menu.style.display = 'none'; // Hide the menu
        },
        goMenu(path) {
            this.selectMenu(path);
            //this.closeMenu();
            this.$goPage(path);
        },
        selectMenu(path) {
            if (path === '/brand') {
                this.selectedMenu = 'home';
            } else if (path === '/brand/study') {
                this.selectedMenu = 'study';
            } else if (path === '/brand/subscription') {
                this.selectedMenu = 'subscription';
            } else if (path === '/brand/partner') {
                this.selectedMenu = 'partner';
            }
        },
        isIndividualCompany() {
            const host = window.location.hostname;
            return (host === 'www.readigo.co.kr' || host === 'readigo.co.kr');
        }
    }
}
</script>
<style>
</style>