<template>

    <div class="top-banner"
         v-if="userProfile && (userProfile.expiredDays > -7 ||  (userProfile.customerPaymentStatus == 'TRIAL' ||userProfile.customerPaymentStatus == 'HOLD' || userProfile.customerPaymentStatus == 'END'))">
        <a class="expired-alarm" @click="$goPage('/mypage/subscription')">
            <h2 class="main-text">{{ userProfile.customerExpireDate }}</h2>
            <h2 class="sub-text">
                구독 패키지 보러 가기
                <div class="arrow-icon">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.09766 18L15.0977 12L9.09766 6" stroke="currentColor" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </h2>
        </a>
    </div>

    <header class="header">
        <!-- MOBILE MENU -->
        <div class="mobile-menu icon-medium" @click="toggleMenu()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6ZM2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12ZM3 17.25C2.58579 17.25 2.25 17.5858 2.25 18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18C21.75 17.5858 21.4142 17.25 21 17.25H3Z"
                      fill="currentColor"/>
            </svg>
        </div>

        <header class="header-main">
            <div class="list dropdown-btn level" v-if="userProfile && userProfile.activeCustomerLevels && userProfile.activeCustomerLevels.length > 0">
                <button class="dropdown-header" @click="levelFilterDisplay()">
                    <div class="text" v-if="userProfile && userProfile.activeCustomerLevels">
                        {{ userProfile.activeCustomerLevels.find((level) => level.level === currentLevel)?.description }}
                    </div>
                    <div class="icon-small">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.70711 9.59441C2.31658 9.20389 2.31658 8.57072 2.70711 8.1802L3.06789 7.81941C3.45842 7.42889 4.09158 7.42889 4.48211 7.81941L11.2929 14.6302C11.6834 15.0207 12.3166 15.0207 12.7071 14.6302L19.5179 7.81941C19.9084 7.42889 20.5416 7.42889 20.9321 7.81941L21.2929 8.1802C21.6834 8.57072 21.6834 9.20389 21.2929 9.59441L12.7071 18.1802C12.3166 18.5707 11.6834 18.5707 11.2929 18.1802L2.70711 9.59441Z"
                                fill="currentColor"/>
                        </svg>
                    </div>
                </button>
                <div class="dropdown-content" v-if="isLevelFilterPopupVisible">
                    <button :class="{ active: activeLevel.level === currentLevel }" class="dropdown-option" v-for="activeLevel in userProfile.activeCustomerLevels" :key="activeLevel.level"
                            @click="changeLevel(activeLevel.level)">
                        {{ activeLevel.description }}
                    </button>
                </div>
            </div>

            <div class="list alarm new" @click="toggleDisplay('alarm')">
                <div class="icon">
                    <span v-if="userAlarmList && userAlarmList.length > 0">N</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.7303 20.5017C13.5545 20.8048 13.3022 21.0564 12.9985 21.2312C12.6949 21.4061 12.3507 21.4982 12.0003 21.4982C11.6499 21.4982 11.3057 21.4061 11.0021 21.2312C10.6985 21.0564 10.4461 20.8048 10.2703 20.5017M18 8.50171C18 6.91041 17.3679 5.38429 16.2426 4.25907C15.1174 3.13385 13.5913 2.50171 12 2.50171C10.4087 2.50171 8.88258 3.13385 7.75736 4.25907C6.63214 5.38429 6 6.91041 6 8.50171C6 15.5017 3 17.5017 3 17.5017H21C21 17.5017 18 15.5017 18 8.50171Z"
                            stroke="currentColor" stroke-width="1.5" stroxzke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <!-- ALARM POP-UP -->
                <div class="list-option" v-if="isAlarmPopupVisible">
                    <div class="icon-small">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 5L5 19M5 5L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="alarm-header">
                        <h2>최근 알림</h2>
                    </div>
                    <a class="popup-item" v-for="userAlarm in userAlarmList" :key="userAlarm.alarmId" @click="$goPage(userAlarm.url)">
                        <div class="title">
                            <div class="category">{{ userAlarm.alarmCategory }}</div>
                            <div class="date">{{ userAlarm.date }}</div>
                        </div>
                        <div class="text">
                            <p>{{ userAlarm.title }}</p>
                        </div>
                    </a>
                    <a href="" class="popup-item empty" v-if="userAlarmList.length < 1">
                        <h3>새로운 알림이 없습니다.</h3>
                    </a>
                </div>
            </div>

            <div class="list mypage" @click="toggleDisplay('profile')">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.27109 18.346C4.27109 18.346 6.50009 15.5 12.0001 15.5C17.5001 15.5 19.7301 18.346 19.7301 18.346M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12.0001 12C12.7957 12 13.5588 11.6839 14.1214 11.1213C14.684 10.5587 15.0001 9.79565 15.0001 9C15.0001 8.20435 14.684 7.44129 14.1214 6.87868C13.5588 6.31607 12.7957 6 12.0001 6C11.2044 6 10.4414 6.31607 9.87877 6.87868C9.31616 7.44129 9.00009 8.20435 9.00009 9C9.00009 9.79565 9.31616 10.5587 9.87877 11.1213C10.4414 11.6839 11.2044 12 12.0001 12Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <!-- MY PAGE POP-UP -->
                <div class="list-option" v-if="isProfilePopupVisible">
                    <div class="icon-small" @click="toggleDisplay('profile')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 5L5 19M5 5L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="user-info">
                        <h2 class="user-name">{{ userProfile.customerName }}</h2>
                        <h2 class="user-id">{{ userProfile.customerId }}</h2>
                    </div>

                    <router-link to="/mypage/account" class="popup-item">
                        <div class="text">계정 관리</div>
                        <div class="arrow-icon">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.09766 18L15.0977 12L9.09766 6" stroke="currentColor" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </router-link>

                    <router-link to="/mypage/subscription" class="popup-item" v-if="$store.getters['auth/isPayCompany']">
                        <div class="text">구독 관리</div>
                        <div class="arrow-icon">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.09766 18L15.0977 12L9.09766 6" stroke="currentColor" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </router-link>
                    <router-link to="/mypage/pay" class="popup-item" v-if="$store.getters['auth/isPayCompany']">
                        <div class="text">결제 및 쿠폰</div>
                        <div class="arrow-icon">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.09766 18L15.0977 12L9.09766 6" stroke="currentColor" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </router-link>
                    <a href="/admin" class="popup-item" v-if="$store.getters['auth/isCompanyOwner']">
                        <div class="text">관리자 페이지</div>
                        <div class="arrow-icon">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.09766 18L15.0977 12L9.09766 6" stroke="currentColor" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </a>
                    <button class="log-out" @click="logout">로그아웃</button>
                </div>
            </div>
        </header>
        <header class="header-sub" v-if="$slots.subHeader">
            <slot name="subHeader"></slot>
        </header>
    </header>
</template>

<script>
import {
    getCommonHeaderInfoApi,
    changeLevelApi
} from '@/api/common/commonApi';
import {logoutApi} from "@/api/auth/accountApi";

export default {
    data() {
        return {
            userProfile: '',
            userAlarmList: [],
            isAlarmPopupVisible: false,
            isProfilePopupVisible: false,
            isLevelFilterPopupVisible: false
        };
    },
    computed: {
        currentLevel() {
            if (this.$store.state.auth.user) {
                return this.$store.state.auth.user.customerLevel;
            }
            return null;
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.getCommonHeaderInfo();
        this.$adjustContentWrapperMargin();
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleMenu() {
            const menu = document.querySelector('nav.menu'); // Select the nav.menu element
            menu.classList.add('on');
        },
        async getCommonHeaderInfo() {
            const data = await getCommonHeaderInfoApi();
            this.userProfile = data.userProfile;
            this.userAlarmList = data.userAlarmList;
        },
        async changeLevel(level) {
            await changeLevelApi(level);
            await this.$store.dispatch('auth/changeLevelAction', {changeLevel: level});
            alert('서비스 레벨이 변경되어 홈화면으로 이동됩니다.');
            this.$router.push('/').then(() => {
                window.location.reload();
            });
        },
        toggleDisplay(toggle) {
            event.stopPropagation();
            if (toggle === 'alarm') {
                this.isAlarmPopupVisible = !this.isAlarmPopupVisible;
                this.isProfilePopupVisible = false;
                this.isLevelFilterPopupVisible = false;
            } else if (toggle === 'profile') {
                this.isAlarmPopupVisible = false;
                this.isProfilePopupVisible = !this.isProfilePopupVisible;
                this.isLevelFilterPopupVisible = false;
            }
        },
        levelFilterDisplay() {
            event.stopPropagation();
            this.isLevelFilterPopupVisible = !this.isLevelFilterPopupVisible;
        },
        handleClickOutside() {
            this.isAlarmPopupVisible = false;
            this.isProfilePopupVisible = false;
        },
        async logout() {
            await logoutApi();
            await this.$store.dispatch('auth/logoutAction');
            if (this.$route.path !== '/auth/login') {
                this.$router.replace('/auth/login');
            }
        }
    }
};
</script>
