const state = {
  isTostVisible: false,
  tostType: '', // 'correct' or 'error' 등으로 메시지 유형 저장
  message: '' // 동적으로 전달받을 메시지
};

const mutations = {
  SHOW_TOST(state, { type, message }) {
    state.isTostVisible = true;
    state.tostType = type;
    state.message = message;
  },
  HIDE_TOST(state) {
    state.isTostVisible = false;
    state.tostType = '';
    state.message = '';
  }
};

const actions = {
  triggerToast({ commit }, { type, message, time }) {
    commit('SHOW_TOST', { type, message });
    setTimeout(
      () => {
        commit('HIDE_TOST');
      },
      time ? time : 1500
    );
  }
};

export default {
  state,
  mutations,
  actions
};
