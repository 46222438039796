/**
 * Global View 관련 데이터 저장소
 */
const view = {
    namespaced: true,
    state     : {
        top         : true, // 화면 최상단 여부
        lnb         : false, // (모바일) LNB 노출 여부
        routerKey   : "DEFAULT_ROUTER_KEY",
        keepAliveKey: "DEFAULT_KEEP_ALIVE_KEY"
    },
    getters   : {
        getTop(state) {
            return state.top;
        },
        getLnb(state) {
            return state.lnb;
        },
        getRouterKey(state) {
            return state.routerKey;
        },
        getKeepAliveKey(state) {
            return state.keepAliveKey;
        }
    },
    mutations : {
        setTop(state, value) {
            state.top = value;
        },
        setLnb(state, value) {
            state.lnb = value;
        },
        setRouterKey(state,  value) {
            state.routerKey = value;
        },
        setKeepAliveKey(state,  value) {
            state.keepAliveKey = value;
        }
    }
};

export default view;