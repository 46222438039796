<template>
  <div>
    <div id="tost" :class="['tost', tostType, { active: isTostVisible }]">
      <div class="wrapper">
        <div class="message">
          <h3>{{ message }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ToastComponent',
    computed: {
      ...mapState({
        isTostVisible: (state) => state.toast.isTostVisible,
        tostType: (state) => state.toast.tostType, // 'correct' 또는 'error'
        message: (state) => state.toast.message // 동적으로 전달받은 메시지
      })
    }
  };
</script>
