import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Vue 애플리케이션 인스턴스를 생성
const app = createApp(App);

app.config.globalProperties.$isPendingUser = function () {
    return this.$store.state.auth.user && this.$store.state.auth.user.paymentStatus === 'PENDING';
};

app.config.globalProperties.$isOnboardUser = function () {
    return this.$store.state.auth.user && this.$store.state.auth.user.completedOnboard;
};

app.config.globalProperties.$isLoginUser = function () {
    return this.$store.state.auth.user
};

app.config.globalProperties.$adjustContentWrapperMargin = function () {
    const header = document.querySelector('.header');
    const contentWrapper = document.querySelector('.content-wrapper');
    const topBanner = document.querySelector('.top-banner');
    if (header && contentWrapper) {
        const headerHeight = header.offsetHeight;
        let newMarginTop = headerHeight;
        header.style.marginTop = '0px';
        if (topBanner) {
            const topBannerHeight = topBanner.offsetHeight;
            newMarginTop += topBannerHeight;
            header.style.marginTop = topBannerHeight + 'px';
        }
        contentWrapper.style.marginTop = newMarginTop + 'px';
    }
};

app.config.globalProperties.$goBack = function (fallbackRoute = '/') {
    if (window.history.length > 1) {
        this.$router.back();
    } else {
        this.$router.push(fallbackRoute);
    }
};

app.config.globalProperties.$goPage = function (page) {
    this.$router.push(page);
};

app.config.globalProperties.$goReplace = function (page) {
    this.$router.replace(page);
};

app.config.globalProperties.$goRefreshUrl = function (path) {
    this.$router.push({
        path: path,
        query: {refresh: new Date().getTime()}
    });
};

// Vuex 스토어와 라우터를 애플리케이션에 등록
app.use(store);
app.use(router);

// 애플리케이션을 마운트
router.isReady().then(() => {
    app.mount('#app'); // 라우터가 완전히 준비된 후에 애플리케이션을 마운트
});
