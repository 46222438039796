import axiosInstance from '@/api/axoiosInstance';

export const checkDuplicateEmailApi = async (email) => {
    const res = await axiosInstance.post(
        '/auth/check-email',
        {
            email: email
        },
        {
            withCredentials: true
        }
    );
    return res;
};

export const signUpApi = async (formData) => {
    const res = await axiosInstance.post('/auth/account', formData, {
        withCredentials: true
    });
    return res;
};

export const signUpGroupApi = async (formData) => {
    const res = await axiosInstance.post('/auth/group-account', formData, {
        withCredentials: true
    });
    return res;
};

export const createVerificationCodeApi = async (formData) => {
    const res = await axiosInstance.post('/auth/verification-code', formData, {
        withCredentials: true
    });
    return res.data;
};

export const checkVerificationCodeApi = async (formData) => {
    const res = await axiosInstance.post('/auth/verification-code-check', formData, {
        withCredentials: true
    });
    return res.data;
};

export const findAccountIdApi = async (formData) => {
    const res = await axiosInstance.post('/auth/find-account-id', formData, {
        withCredentials: true
    });
    return res.data;
};

export const findAccountPasswordApi = async (formData) => {
    const res = await axiosInstance.post('/auth/find-account-password', formData, {
        withCredentials: true
    });
    return res.data;
};

export const logoutApi = async () => {
    const res = await axiosInstance.post('/auth/logout', null, {
        withCredentials: true
    });
    return res.data;
};

export const checkCompanyDomainApi = async () => {
    const res = await axiosInstance.post('/auth/check-company-domain', null, {
        withCredentials: true
    });
    return res.data;
};