<template>
    <nav class="menu" style="background-color: whitesmoke;">

        <div class="close icon-medium" @click="closeMenu()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 5L5 19M5 5L19 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="menu-list">
            <a @click="goMenu('/')" class="logo">
                <img class="logo-text" src="@/assets/images/logo/logo_text.svg" alt="logo"/>
                <img class="logo-icon" src="@/assets/images/logo/logo_icon.svg" alt="logo"/>
            </a>

            <a @click="goMenu('/')" class="menu-item" :class="{ 'current-item': currentContextPath === '' }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 22V12H15V22M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">홈</div>
            </a>

            <a @click="goMenu('/education')" class="menu-item"
               :class="{'current-item':currentContextPath === 'education' ||currentContextPath === 'anchor' ||currentContextPath === 'discussion' ||currentContextPath === 'selfstudy'}">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.24375 19.3352C8.34505 19.9902 8.45065 20.5863 8.539 21.0571C8.66335 21.7195 9.1345 22.2515 9.8009 22.3523C10.3239 22.4315 11.0506 22.4999 11.9995 22.4999C12.902 22.4999 13.6436 22.438 14.2082 22.3639C15.0003 22.2599 15.5552 21.6011 15.6413 20.8068L15.8017 19.3262M6.75805 16.6917C4.77444 15.1356 3.5 12.7167 3.5 10C3.5 5.3056 7.3056 1.5 12 1.5C16.6944 1.5 20.5 5.3056 20.5 10C20.5 12.7167 19.2255 15.1356 17.2419 16.6917C17.0811 17.3414 16.8996 17.9647 16.7563 18.4312C16.603 18.9303 16.1884 19.2931 15.6691 19.3473C14.9723 19.4201 13.7978 19.5 12 19.5C10.2022 19.5 9.02765 19.4201 8.33085 19.3473C7.8116 19.2931 7.39705 18.9303 7.24375 18.4312C7.10045 17.9647 6.91885 17.3414 6.75805 16.6917Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">학습</div>
            </a>

            <a @click="goMenu('/article')" class="menu-item" :class="{ 'current-item': currentContextPath === 'article' }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.2127 3V8.4H19.7412M16.0556 12.9H8.68423M16.0556 16.5H8.68423M10.5271 9.30001H8.68423M14.2142 3H6.84284C6.35409 3 5.88535 3.18964 5.53975 3.52721C5.19416 3.86477 5 4.32261 5 4.8V19.2C5 19.6774 5.19416 20.1352 5.53975 20.4728C5.88535 20.8104 6.35409 21 6.84284 21H17.8998C18.3886 21 18.8573 20.8104 19.2029 20.4728C19.5485 20.1352 19.7427 19.6774 19.7427 19.2V8.4L14.2142 3Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">영자신문</div>
            </a>

            <a @click="goMenu('/vocabulary')" class="menu-item" :class="{'current-item': currentContextPath === 'vocabulary'}">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.49927 7.66227L6.49927 7.66227M2.49927 15.6623H6.49927M2.49927 11.6622H6.49927M6.27861 3H18.7231C19.7049 3 20.5008 3.89543 20.5008 5V19C20.5008 20.1046 19.7049 21 18.7231 21H6.27861C5.29677 21 4.50083 20.1046 4.50083 19V5C4.50083 3.89543 5.29677 3 6.27861 3Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">단어장</div>
            </a>

            <!--
            <a @click="goMenu('/challenge')" class="menu-item" :class="{ 'current-item': currentContextPath === 'challenge' }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.2293 4.49352H5.61075C5.10922 4.49352 4.62823 4.69275 4.27359 5.04738C3.91896 5.40202 3.71973 5.883 3.71973 6.38453V19.6217C3.71973 20.1232 3.91896 20.6042 4.27359 20.9588C4.62823 21.3135 5.10922 21.5127 5.61075 21.5127H18.8479C19.3494 21.5127 19.8304 21.3135 20.185 20.9588C20.5397 20.6042 20.7389 20.1232 20.7389 19.6217V13.0031M19.3206 3.07526C19.6968 2.69911 20.2069 2.48779 20.7389 2.48779C21.2709 2.48779 21.781 2.69911 22.1572 3.07526C22.5333 3.4514 22.7446 3.96157 22.7446 4.49352C22.7446 5.02547 22.5333 5.53564 22.1572 5.91179L13.1748 14.8941L9.39278 15.8396L10.3383 12.0576L19.3206 3.07526Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">챌린지</div>
            </a>
            -->
            <a @click="goMenu('/learning-manage/monthly-report')" class="menu-item"
               :class="{'current-item':currentContextPath === 'report' ||currentContextPath === 'report-participation' ||currentContextPath === 'learning-manage'}">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 20V10M18 20V4M6 20V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">학습관리</div>
            </a>

        </div>

        <div class="menu-list">
            <a @click="goMenu('/guide')" class="menu-item" :class="{ 'current-item': currentContextPath === 'guide' }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 14.5001C4 14.5001 5 13.6667 8 13.6667C11 13.6667 13 15.3334 16 15.3334C19 15.3334 20 14.5001 20 14.5001V4.50008C20 4.50008 19 5.33341 16 5.33341C13 5.33341 11 3.66675 8 3.66675C5 3.66675 4 4.50008 4 4.50008V14.5001ZM4 14.5001V20.3334"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">가이드</div>
            </a>
            <a @click="goMenu('/customer/faq')" class="menu-item" :class="{ 'current-item': currentContextPath === 'customer' }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.08984 9.00001C9.32495 8.33167 9.789 7.76811 10.3998 7.40914C11.0106 7.05016 11.7287 6.91894 12.427 7.03872C13.1253 7.15849 13.7587 7.52153 14.2149 8.06353C14.6712 8.60554 14.9209 9.29153 14.9198 10C14.9198 12 11.9198 13 11.9198 13M12.0008 17H12.0108M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">고객센터</div>
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'ServiceLnb',
    computed: {
        currentContextPath() {
            return this.$route.path.split('/')[1];
        }
    },
    methods: {
        closeMenu() {
            const menu = document.querySelector('nav.menu'); // Select the nav.menu element
            menu.classList.remove('on'); // Hide the menu
        },
        goMenu(contextPath) {
            this.$goPage(contextPath);
            this.closeMenu();
        }
    },
};
</script>
