import axiosInstance from '@/api/axoiosInstance';

export const getCommonHeaderInfoApi = async () => {
    const res = await axiosInstance.get('/common/header-info', {
        withCredentials: true
    });
    return res.data;
};

export const changeLevelApi = async (level) => {
    const res = await axiosInstance.post('/common/change-level',
        {
            customerLevel: level
        },
        {
            withCredentials: true
        });
    return res.data;
};