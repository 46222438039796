/**
 * Router index
 */
import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';

const routes = [
    {
        path: '/',
        component: () => import('@/views/home/ServiceHome.vue'),
        meta: {requiresHeader: true, requiresLnb: true}
    },
    {
        path: '/brand',
        component: () => import('@/views/brand/BrandHome.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/brand/study',
        component: () => import('@/views/brand/BrandStudy.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/brand/subscription',
        component: () => import('@/views/brand/BrandSubscription.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/brand/partner',
        component: () => import('@/views/brand/BrandPartner.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/onboard-card',
        component: () => import('@/views/onboard/OnBoardCard.vue'),
        meta: {requiresHeader: false, requiresLnb: false},
    },
    {
        path: '/education',
        component: () => import('@/views/education/EducationHome.vue'),
        meta: {requiresHeader: true},
        children: []
    },
    {
        path: '/article',
        component: () => import('@/views/article/ArticleHome.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/article/detail/:id',
        name: 'ArticleDetail',
        component: () => import('@/views/article/ArticleDetail.vue'),
        meta: {requiresHeader: false, requiresLnb: false},
    },
    {
        path: '/vocabulary',
        component: () => import('@/views/vocabulary/VocabularyHome.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/vocabulary/my',
        name: 'MyVocabulary',
        component: () => import('@/views/vocabulary/MyVocabulary.vue'),
        meta: {requiresHeader: false, requiresLnb: false},
        props: route => ({tab: route.query.tab})
    },
    {
        path: '/vocabulary/recommend',
        name: 'RecommendVocabulary',
        component: () => import('@/views/vocabulary/RecommendVocabulary.vue'),
        meta: {requiresHeader: false, requiresLnb: false},
        props: route => ({tab: route.query.tab})
    },
    {
        path: '/vocabulary/today',
        component: () => import('@/views/vocabulary/TodayVocabulary.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/vocabulary/quiz-flashcard',
        component: () => import('@/views/vocabulary/component/QuizFlashCard.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/vocabulary/quiz-multiple',
        component: () =>
            import('@/views/vocabulary/component/QuizMultipleChoiceEnglish.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/vocabulary/quiz-dictation',
        component: () => import('@/views/vocabulary/component/QuizDictation.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/vocabulary/quiz-result',
        component: () => import('@/views/vocabulary/component/QuizResult.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/challenge',
        component: () => import('@/views/challenge/ChallengeHome.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/challenge/review',
        component: () => import('@/views/challenge/ChallengeReview.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/challenge/attempt/:id',
        component: () => import('@/views/challenge/ChallengeAttempt.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/learning-manage/monthly-report',
        component: () => import('@/views/report/LearningMonthlyReport.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/report-participation',
        component: () => import('@/views/report/ReportParticipation.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/learning-manage/setting',
        component: () => import('@/views/report/LearningManageSetting.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/selfstudy/:id',
        name: 'SelfStudy',
        component: () => import('@/views/selfstudy/SelfStudy.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/selfstudy/result',
        component: () => import('@/views/selfstudy/SelfStudyResult.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/conversation',
        name: 'ConversationHome',
        component: () => import('@/views/conversation/ConversationHome.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/conversation/register/:id',
        name: 'ConversationRegister',
        component: () => import('@/views/conversation/ConversationRegister.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/conversation/view/:id',
        name: 'ConversationView',
        component: () => import('@/views/conversation/ConversationView.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/anchor',
        name: 'AnchorHome',
        component: () => import('@/views/anchor/AnchorHome.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/anchor/view/:id',
        name: 'AnchorView',
        component: () => import('@/views/anchor/AnchorView.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/anchor/register/:id',
        name: 'AnchorRegister',
        component: () => import('@/views/anchor/AnchorRegister.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/discussion/detail/:id',
        name: 'DiscussionDetail',
        component: () => import('@/views/debate/DiscussionDetail.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/mypage/account',
        name: 'MypageAccount',
        component: () => import('@/views/mypage/MypageAccount.vue'),
        meta: {requiresHeader: true, requiresLnb: true}
    },
    {
        path: '/mypage/subscription',
        name: 'MypageSubscription',
        component: () => import('@/views/mypage/MypageSubscription.vue'),
        meta: {requiresHeader: true, requiresLnb: true}
    },
    {
        path: '/mypage/subscription/checkout',
        name: 'SubscriptionCheckout',
        component: () => import('@/views/mypage/SubscriptionCheckout.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/mypage/pay',
        name: 'MypagePay',
        component: () => import('@/views/mypage/MypagePay.vue'),
        meta: {requiresHeader: true, requiresLnb: true}
    },
    {
        path: '/guide',
        component: () => import('@/views/guide/CustomerGuide.vue'),
        meta: {requiresHeader: true}
    },
    {
        path: '/guide/detail/:id',
        component: () => import('@/views/guide/CustomerGuideDetail.vue'),
        meta: {requiresHeader: false, requiresLnb: true}
    },
    {
        path: '/customer/faq',
        name: 'CustomerFaq',
        component: () => import('@/views/customer/CustomerFaq.vue')
    },
    {
        path: '/customer/notice',
        name: 'CustomerNotice',
        component: () => import('@/views/customer/CustomerNotice.vue')
    },
    {
        path: '/customer/notice/detail/:id',
        name: 'CustomerNoticeDetail',
        component: () => import('@/views/customer/CustomerNoticeDetail.vue'),
        meta: {requiresHeader: false, requiresLnb: true}
    },
    {
        path: '/customer/inquiry',
        name: 'CustomerInquiry',
        component: () => import('@/views/customer/CustomerInquiry.vue')
    },
    {
        path: '/customer/inquiry/detail/:id',
        name: 'CustomerInquiryDetail',
        component: () => import('@/views/customer/CustomerInquiryDetail.vue'),
        meta: {requiresHeader: false, requiresLnb: true}
    },
    {
        path: '/customer/inquiry/edit',
        name: 'CustomerInquiryEdit',
        component: () => import('@/views/customer/CustomerInquiryEdit.vue'),
        meta: {requiresHeader: false, requiresLnb: true}
    },
    {
        path: '/level-test',
        name: 'LevelTest',
        component: () => import('@/views/leveltest/LevelTest.vue'),
        meta: {requiresHeader: false, requiresLnb: false}
    },
    {
        path: '/auth/auto-login',
        name: 'DevelopersLogin',
        component: () => import('@/views/auth/DevelopersLogin.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/change-password',
        name: 'UserChangePassword',
        component: () => import('@/views/auth/UserChangePassword.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/login',
        name: 'UserLogin',
        component: () => import('@/views/auth/UserLogin.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/login/oauth-redirect',
        name: 'UserOAuthRedirect',
        component: () => import('@/views/auth/UserOAuthRedirect.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/find-account',
        name: 'FindAccount',
        component: () => import('@/views/auth/FindAccount.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/signup',
        name: 'SignUp',
        component: () => import('@/views/auth/SignUp.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/auth/policy',
        name: 'UserPolicy',
        component: () => import('@/views/auth/UserPolicy.vue'),
        meta: {requiresFooter: false}
    },
    {
        path: '/admin',
        redirect: '/admin/'
    }
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // 이전 스크롤 위치로 이동
            return savedPosition;
        } else {
            // 최상단으로 스크롤
            return {top: 0};
        }
    }
});

function isAuthenticated() {
    return store.getters['auth/isAuthenticated'];
}

function isExpiredPayment() {
    return store.getters['auth/isExpiredPayment'];
}

function isLevelTestUser() {
    return store.getters['auth/isLevelTestUser'];
}

function isOnboardCardUser() {
    return store.state.auth.user && !store.state.auth.user.completedOnboard;
}


function isIndividualCompany() {
    const host = window.location.hostname;
    return (host === 'www.readigo.co.kr' || host === 'readigo.co.kr');
}

// 네비게이션 가드 설정
router.beforeEach((to, from, next) => {

    //console.log('to.path가드 : ' + to.path + ' isAuthenticated : ' + isAuthenticated());


    // 인증되지 않은 사용자가 인증이 필요한 페이지에 접근하는 경우 로그인 페이지로 리다이렉트
    if (
        !isAuthenticated() &&
        to.path !== '/' &&
        to.path !== '/brand' && to.path !== '/brand/study' && to.path !== '/brand/subscription' && to.path !== '/brand/partner' &&
        to.path !== '/auth/login' && to.path !== '/auth/auto-login' && to.path !== '/auth/change-password' && to.path !== '/auth/login/oauth-redirect' && to.path !== '/auth/find-account' && to.path !== '/auth/signup' && to.path !== '/auth/policy' && to.path !== '/oauth2/authorization/google'
    ) {
        //console.log('인증되지 않은 사용자가 인증이 필요한 페이지에 접근하는 경우 로그인 페이지로 리다이렉트');
        next('/auth/login');
    }
    //
    else if (!isAuthenticated() && to.path !== '/brand' && to.path == '/' && isIndividualCompany()) {
        //console.log('to.path12 : ' + to.path);
        next('/brand');
    }
    //
    else if (!isAuthenticated() && (to.path === '/brand/subscription' || to.path === '/brand/partner') && !isIndividualCompany()) {
        //console.log('to.path13 : ' + to.path);
        next('/auth/login');
    }
    // 인증된 사용자가 로그인 페이지나 회원가입 페이지로 접근하려는 경우 홈으로 리다이렉트
    else if (isAuthenticated() && (to.path.startsWith('/auth') && to.path !== '/auth/auto-login')) {
        //console.log('인증된 사용자가 로그인 페이지나 회원가입 페이지로 접근하려는 경우 홈으로 리다이렉트 : ' + to.path);
        next('/');
    }
    // 인증된 사용자가 결제를 완료하지 않은 경우, 결제가 필요한 페이지로 리다이렉트
    else if (isAuthenticated() && isExpiredPayment() && (!to.path.startsWith('/mypage') && !to.path.startsWith('/customer') && !to.path.startsWith('/onboard-card'))) { // && !exemptPa
        //console.log('인증된 사용자가 결제를 완료하지 않은 경우, 결제가 필요한 페이지로 리다이렉트');
        next('/mypage/subscription');
    }
    // 인증된 사용자가 레벨테스트가 필요한 사용자인 경우
    else if (isAuthenticated() && isLevelTestUser() && to.path !== '/level-test') {
        //console.log('인증된 사용자가 레벨테스트가 필요한 사용자인 경우');
        next('/level-test');
    }
    // 인증된 사용자가 온보드 카드를 수행해야 하는 경우
    else if (isAuthenticated() && !isExpiredPayment() && !isLevelTestUser() && isOnboardCardUser() && to.path !== '/onboard-card') {
        //console.log('인증된 사용자가 온보드 카드를 수행해야 하는 경우');
        next('/onboard-card');
    }
    // 그 외에는 정상적으로 이동
    else {
        //console.log('그 외에는 정상적으로 이동');
        next();
    }
});

export default router;