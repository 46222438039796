<template>
    <ServiceLayout v-if="isLoginUser() && (!isBrandRoute && !isAuthRoute)"/>
    <BrandLayout v-else/>
    <ToastComponent/>
</template>

<script>
import store from "@/store";

import('@/assets/css/main.css');
import('@/assets/css/base/typography.css');
import('@/assets/css/base/icon.css');
import('@/assets/css/component/header.css');
import('@/assets/css/component/banner.css');
import('@/assets/css/component/button.css');
import('@/assets/css/component/frame.css');
import('@/assets/css/component/footer.css');
import('@/assets/css/component/list.css');
import('@/assets/css/component/menu.css');
import('@/assets/css/component/navigation.css');
import('@/assets/css/component/category.css');
import('@/assets/css/component/progress.css');
import('@/assets/css/component/pagination.css');
import('@/assets/css/component/preview.css');
import('@/assets/css/component/dropdown.css');
import('@/assets/css/component/input.css');
import('@/assets/css/component/tag.css');
import('@/assets/css/component/toggle.css');
import('@/assets/css/component/tost.css');
import('@/assets/css/component/popup.css');
import('@/assets/css/component/snackbar.css');
import('@/assets/css/component/reaction.css');
import('@/assets/css/component/hover-info.css');
import('@/assets/css/component/graph.css');
import('@/assets/css/component/widget.css');
import('@/assets/css/component/sub-content.css');

import BrandLayout from '@/layouts/BrandLayout.vue';
import ServiceLayout from '@/layouts/ServiceLayout.vue';
import ToastComponent from "@/components/common/ToastComponent.vue";

export default {
    name: 'App',
    components: {
        BrandLayout,
        ServiceLayout,
        ToastComponent
    },
    data() {
        return {
            isBrandRoute: false,
            isAuthRoute: false,
            isDefaultRoute: false
        };
    },
    watch: {
        $route(to) {
            this.checkRoute(to.path);
        }
    },
    mounted() {
        this.checkRoute(this.$route.path);
    },
    methods: {
        checkRoute(path) {
            this.isBrandRoute = path.startsWith('/brand')
            this.isAuthRoute = path.startsWith('/auth');
        },
        isLoginUser() {
            return store.getters['auth/isAuthenticated'];
        }
    }
};
</script>
