<template>
    <div class="content">
        <BrandHeader :key="$route.fullPath"/>
        <router-view/>
        <BrandFooter v-if="$route.meta.requiresFooter !== false"/>
    </div>
</template>

<script>
import BrandHeader from "@/components/layout/BrandHeader.vue";
import BrandFooter from "@/components/layout/BrandFooter.vue";

export default {
    name: 'BrandLayout',
    components: {BrandFooter, BrandHeader},
    beforeRouteUpdate(to, from, next) {
        next();
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/page/brand.scss';

.header {
    margin-top: 0px;
}

.header-brand .brand-menu {
    font-size: 16px;
    gap: 40px;
}

.header-brand .brand-menu a {
    color: #111111;
}
</style>