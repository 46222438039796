const state = {
  isPopupVisible: false,
  message: '' // 동적으로 전달받을 메시지
};

const mutations = {
  SHOW_POPUP(state, { message }) {
    state.isTostVisible = true;
    state.message = message;
  },
  HIDE_POPUP(state) {
    state.isTostVisible = false;
    state.message = '';
  }
};

const actions = {
  triggerPopup({ commit }, { message }) {
    commit('SHOW_POPUP', { message });
  }
};
export default {
  state,
  mutations,
  actions
};

