/**
 * Vuex Index
 */
import {createStore} from 'vuex';
import auth from '@/store/auth';
import view from '@/store/view';
import toast from '@/store/toast';
import popup from '@/store/popup';

import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    modules: {
        auth,
        view,
        toast,
        popup
    },
    plugins: [
        createPersistedState({
            key: 'rg_profile',
            // 로그아웃 시 삭제되도록 paths에서 제외
            paths: ['auth.user'],
            storage: window.localStorage, // 또는 sessionStorage
        })
    ]
});

export default store;
