/**
 * Auth 관련 데이터 저장소
 */
const auth = {
    namespaced: true,
    state: {
        user: {
            isAuthenticated: false,
            companyOwner: false,
            companyType: null,
            customerLevel: null,
            paymentStatus: null,
            completedOnboard: false,
            useConversation: false,
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setPaymentStatus(state, paymentStatus) {
            state.user.paymentStatus = paymentStatus;
        },
        setCustomerLevel(state, customerLevel) {
            state.user.customerLevel = customerLevel;
        },
        setCompletedOnboard(state) {
            state.user.completedOnboard = true;
        },
        logout(state) {
            state.user = {
                isAuthenticated: false,
                companyOwner: false,
                companyType: null,
                customerLevel: null,
                paymentStatus: null,
                completedOnboard: false,
                useConversation: false,
            };
            localStorage.removeItem('rg_profile');
        }
    },
    actions: {
        loginAction({commit}, {user}) {
            commit('setUser', user);
        },
        logoutAction({commit}) {
            commit('logout');
        },
        subscribeProductAction({commit}, {paymentStatus}) {
            commit('setPaymentStatus', paymentStatus);
        },
        completeLevelTestAction({commit}, {resultLevel}) {
            commit('setCustomerLevel', resultLevel);
        },
        changeLevelAction({commit}, {changeLevel}) {
            commit('setCustomerLevel', changeLevel);
        },
        completeOnboardAction({commit}) {
            commit('setCompletedOnboard');
        }
    },
    getters: {
        isPayCompany: (state) => state.user.companyType === 'INDIVIDUAL',
        isAuthenticated: (state) => state.user && state.user.customerLevel !== null,
        isCompanyOwner: (state) => state.user && state.user.companyOwner,
        isUseConversation: (state) => state.user && state.user.useConversation,
        isLevelTestUser: (state) => state.user && (state.user.companyType === 'INDIVIDUAL' && (state.user.customerLevel === null || state.user.customerLevel === 'NONE') && state.user.paymentStatus === 'PENDING'),
        isExpiredPayment: (state) => state.user && (state.user.paymentStatus === 'HOLD' || state.user.paymentStatus === 'END'),
        getUser: (state) => state.user
    }
};

export default auth;
